import Select, { Item } from '@gameonsports/components/cjs/Select'
import { Field, FieldProps, FormikProps } from 'formik-1.5.8'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { DiscoverOrganisationTeamsDiscoverTeams } from '../../generated/graphql'
import { media } from '../../utils/styled-components-utils'

const Form = styled.form`
  /* Display flex for IE11 */
  display: flex;
  display: grid;
  grid-gap: 0.5rem;

  span {
    padding: 0.5rem 2rem 0.5rem 0.5rem;
  }

  ${media.tablet`
    grid-template-columns: repeat(3, 1fr);

    > * {
      width: 9rem;
    }
  `}
`

export interface TeamListFilterFormValues {
  grade: string
  gender: string
  age: string
}

interface TeamListFilterFormProps {
  grades: Item[]
  genders: Item[]
  ageGroups: Item[]
  teams: DiscoverOrganisationTeamsDiscoverTeams[]
  onFilterChange: () => void
}

const TeamListFilterForm: React.FC<
  FormikProps<TeamListFilterFormValues> & TeamListFilterFormProps
> = ({
  touched,
  values,
  setFieldValue,
  setTouched,
  handleSubmit,
  grades,
  genders,
  ageGroups,
  teams,
  onFilterChange,
}) => {
  useEffect(() => {
    onFilterChange()
    // eslint-disable-next-line
  }, [values.grade, values.gender, values.age])

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Field name="grade">
        {({ field }: FieldProps) => (
          <Select
            {...field}
            id={field.name}
            data-testid="team-list-filter-grade-field"
            hideLabel
            label="Grade"
            items={grades.filter(grade =>
              teams.some(t => !!t.grade && t.grade.id === grade.value),
            )}
            placeholder="All grades"
            placeholderSelectable
            onChange={value => setFieldValue(field.name, value)}
            onBlur={() => setTouched({ ...touched, [field.name]: true })}
          />
        )}
      </Field>
      <Field name="gender">
        {({ field }: FieldProps) => (
          <Select
            {...field}
            id={field.name}
            data-testid="team-list-filter-gender-field"
            hideLabel
            label="Gender"
            items={genders.filter(gender =>
              teams.some(t => t.gender.value === gender.value),
            )}
            placeholderSelectable
            placeholder="All genders"
            onChange={value => setFieldValue(field.name, value)}
            onBlur={() => setTouched({ ...touched, [field.name]: true })}
          />
        )}
      </Field>
      <Field name="age">
        {({ field }: FieldProps) => (
          <Select
            {...field}
            id={field.name}
            data-testid="team-list-filter-age-field"
            hideLabel
            label="Age group"
            items={ageGroups.filter(ageGroup =>
              teams.some(t => t.ageGroup.value === ageGroup.value),
            )}
            placeholderSelectable
            placeholder="All age groups"
            onChange={value => setFieldValue(field.name, value)}
            onBlur={() => setTouched({ ...touched, [field.name]: true })}
          />
        )}
      </Field>
    </Form>
  )
}

export default TeamListFilterForm
