import { useQuery } from '@apollo/client'
import { gql } from '@apollo/client'
import { useToggle } from '@gameonsports/components/cjs/_hooks/useToggle'
import { mq } from '@gameonsports/components/cjs/_utils/styled-components-utils'
import Button from '@gameonsports/components/cjs/Button'
import Icon from '@gameonsports/components/cjs/Icon'
import List from '@gameonsports/components/cjs/List'
import ListRow from '@gameonsports/components/cjs/ListRow'
import Loader from '@gameonsports/components/cjs/Loader'
import Notification from '@gameonsports/components/cjs/Notification'
import Pill from '@gameonsports/components/cjs/Pill'
import { Text } from '@gameonsports/components/cjs/TextV3'
import WizardStepper, {
  WizardStep,
} from '@gameonsports/components/cjs/WizardStepper'
import loadable from '@loadable/component'
import { Link, RouteComponentProps } from '@reach/router'
import { Formik } from 'formik-1.5.8'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'
import BoxContainer from '../../components/BoxContainer'
import FullWidthBlock from '../../components/FullWidthBlock'
import OrganisationPageWrapper from '../../components/OrganisationPageWrapper'
import OrgLogoName from '../../components/OrgLogoName'
import PageLoader from '../../components/PageLoader'
import SectionContainer from '../../components/SectionContainer'
import TeamListFilterForm, {
  TeamListFilterFormValues,
} from '../../forms/TeamListFilterForm'
import {
  DiscoverOrganisationTeamsQuery,
  DiscoverOrganisationTeamsQueryVariables,
  DiscoverSeasonStatusType,
  OrganisationDetailsFragmentDoc,
  TenantContactRolesConfigurationFragmentDoc,
} from '../../generated/graphql'
import { setOrganisationContacts } from '../../utils/organisation'
import slugify from '../../utils/slugify'
import { media } from '../../utils/styled-components-utils'

export const DISCOVER_ORGANISATION_TEAMS = gql`
  query discoverOrganisationTeams(
    $seasonCode: String!
    $seasonId: ID!
    $organisationCode: String!
    $organisationId: ID!
  ) {
    discoverSeason(seasonID: $seasonCode) {
      id
      name
      competition {
        id
        name
        organisation {
          id
          name
          logo {
            sizes {
              url
              dimensions {
                width
                height
              }
            }
          }
        }
      }
      status {
        name
        value
      }
      grades {
        id
        name
      }
    }
    tenantConfiguration {
      label
      competition {
        ageGroups {
          name
          value
        }
        genders {
          name
          value
        }
      }
      ...TenantContactRolesConfiguration
    }
    discoverTeams(
      filter: { seasonID: $seasonId, organisationID: $organisationId }
    ) {
      id
      name
      gender {
        name
        value
      }
      ageGroup {
        name
        value
      }
      grade {
        id
        name
      }
    }
    discoverOrganisation(code: $organisationCode) {
      ...OrganisationDetails
    }
  }
  ${OrganisationDetailsFragmentDoc}
  ${TenantContactRolesConfigurationFragmentDoc}
`

const FourOhFour = loadable(
  () => import('../404' /* webpackChunkName: "404" */),
  { fallback: <PageLoader /> },
)

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @supports (display: grid) {
    display: grid;
    align-items: center;
    grid-gap: 1rem;
  }

  ${media.tablet`
    grid-template-columns: 2fr 3fr;
    align-items: start;

    & > * + * {
      margin-top: 1rem;

      @supports (display: grid) {
        margin-top: 0;
      }
    }
  `}
`

const StyledFullWidthBlock = styled(FullWidthBlock)`
  display: grid;
  grid-gap: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
`

const StyledList = styled(List)`
  width: 100%;
  grid-column: 1 / -1;
  border-left: 1px solid ${props => props.theme.bodyBackground};
  border-right: 1px solid ${props => props.theme.bodyBackground};
  border-top: 1px solid ${props => props.theme.bodyBackground};

  ${media.tablet`
    border-top: none;
    margin-top: 1rem;
  `}
`

const StyledSectionContainer = styled(SectionContainer)`
  & > ol {
    padding: 2rem;

    /* stylelint-disable-next-line no-descending-specificity */
    ${mq.up('tablet')} {
      padding: 0 2rem;
    }
  }
`

const RowButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  grid-column: 2;
  grid-row: 1 / 5;
  padding: 0.5rem;
  background-color: ${props => props.theme.blackberry100};

  svg {
    transition: fill 150ms ease;
  }

  ${media.tablet`
    display: none;
  `}
`

const SelectButton = styled(Button)`
  display: none;

  ${media.tablet`
    display: block;
  `}
`

const StyledRow = styled(ListRow).attrs({ as: Link })<{ to?: string }>`
  display: flex;
  word-break: break-word;
  text-decoration: none;
  border-bottom: 1px solid ${props => props.theme.bodyBackground};
  color: ${props => props.theme.bodyCopy};

  & > *:first-child {
    width: 100%;
  }

  @supports (display: grid) {
    display: grid;
    grid-template-columns: 1fr auto;
  }

  &[href] {
    transition: background-color 150ms ${props => props.theme.easeInOutCirc};

    &:hover,
    &:focus {
      background-color: ${props => props.theme.blackberry100};

      ${SelectButton} {
        background-color: ${props => props.theme.buttonTertiary};
        color: ${props => props.theme.white400};
      }
    }
  }

  ${media.tablet`
    grid-gap: 2rem;
    grid-template-columns: 1fr;
    align-items: center;
    min-height: 3.125rem;
  `}
`

const RowBody = styled.div`
  display: -ms-grid; /* stylelint-disable-line */
  display: grid;
  align-items: center;
  padding: 1rem;

  ${media.tablet`
    padding: 0;
    -ms-grid-columns: 2fr 2fr 1fr 1fr 7.75rem;
    grid-template-columns: 2fr 2fr 1fr 1fr 7.75rem;
    grid-gap: 2rem;
    justify-items: start;

    & > * { display:block; }
    & > *:nth-child(2) { -ms-grid-column: 2; }
    & > *:nth-child(3) { -ms-grid-column: 3; }
    & > *:nth-child(4) { -ms-grid-column: 4; }
    & > *:nth-child(5) { -ms-grid-column: 5; }

    & > *:first-of-type {
      padding-left: 1.5rem;
    }
  `}
`

const StyledHeadingRow = styled.li`
  display: none;
  background-color: ${props => props.theme.grey400};
  text-transform: uppercase;
  color: ${props => props.theme.darkGrey400};
  padding: 0.5rem 0;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.bodyBackground};

  ${media.tablet`
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 2fr 2fr 1fr 1fr 7.75rem;
    grid-template-columns: 2fr 2fr 1fr 1fr 7.75rem;
    grid-gap: 2rem;
    justify-items: start;
    padding: 0.5rem 0;

    & > * { display:block; }
    & > *:nth-child(2) { -ms-grid-column: 2; }
    & > *:nth-child(3) { -ms-grid-column: 3; }
    & > *:nth-child(4) { -ms-grid-column: 4; }

    > *:first-of-type {
      padding-left: 1.5rem;
    }
  `}
`

const TeamDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;

  ${media.tablet`
    margin-bottom: 0;
  `}
`

const FilterButton = styled(Button)`
  margin-left: auto;

  ${media.tablet`
    display: none;
  `}
`

const FiltersContainer = styled.div<{ visible?: boolean }>`
  display: ${props => (props.visible ? 'grid' : 'none')};
  grid-gap: 1rem;
  margin-bottom: 2rem;

  ${media.tablet`
    /* Flex for IE11 */
    display: flex;
    display: grid;
    grid-gap: 0;
    justify-items: flex-end;
    grid-auto-flow: column;
    margin-bottom: 0;

    @supports (display: grid) {
      margin-top: 0;
      align-self: center;
    }
  `}
`

const SeasonStep = styled.div`
  display: flex;
  flex-direction: column;
`

const TeamHeader = styled.div`
  display: grid;
  grid-gap: 0.25rem;
`

const ResetButton = styled(Button)`
  font-style: italic;
  color: ${props => props.theme.darkGrey400};
  padding: 0;

  span {
    justify-content: start;
  }

  ${media.tablet`
    padding: 0.5rem 1rem;
  `}
`

const SeasonSubHeader = styled.div`
  display: grid;
  grid-gap: 0.5rem;

  ${media.tablet`
    grid-gap: 1rem;
    grid-template-columns: auto 1fr;
    align-items: center;
  `}
`

const AssociationLogoName = styled(OrgLogoName)`
  padding: 0;
  grid-column: 1 / -1;

  .organisation-name {
    font-size: 1rem;
    font-weight: 700;
  }

  svg {
    width: 2rem;
    height: 2rem;
  }

  ${media.tablet`
    .organisation-name {
      font-size: 1.25rem;
    }

    svg {
      width: 2.5rem;
      height: 2.5rem;
    }
  `}
`

const CompName = styled(Text)`
  margin: 0;
`

const TeamList: React.FC<
  RouteComponentProps<{
    tenant: string
    seasonId: string
    organisationId: string
  }>
> = ({ tenant, seasonId, organisationId }) => {
  const [excludedTeams, setExcludedTeams] = useState<
    Array<
      NonNullable<
        NonNullable<DiscoverOrganisationTeamsQuery['discoverTeams']>[number]
      >
    >
  >([])
  const { data, loading, error } = useQuery<
    DiscoverOrganisationTeamsQuery,
    DiscoverOrganisationTeamsQueryVariables
  >(DISCOVER_ORGANISATION_TEAMS, {
    variables: {
      // We need to pass all these variables in for different parameter types. Need API to update schema.
      seasonCode: String(seasonId),
      seasonId: String(seasonId),
      organisationCode: String(organisationId),
      organisationId: String(organisationId),
    },
    onError: () => null,
  })

  const [filtersOpen, toggleFiltersOpen] = useToggle(false)

  if (loading) {
    return <Loader />
  }

  if (
    error ||
    !data ||
    !data.discoverTeams ||
    !data.tenantConfiguration ||
    !data.discoverOrganisation
  ) {
    return (
      <StyledFullWidthBlock>
        <Notification variant="error">
          There was an error retrieving the teams for this season. Please try
          again later.
        </Notification>
      </StyledFullWidthBlock>
    )
  }

  if (!data.discoverSeason) {
    return <FourOhFour />
  }

  const {
    discoverSeason: season,
    tenantConfiguration,
    discoverTeams: teams,
    discoverOrganisation: organisation,
  } = data

  const filterTeams = ({ grade, gender, age }: TeamListFilterFormValues) => {
    setExcludedTeams(
      teams.filter(team => {
        let match = true

        if (grade) {
          match = !!team.grade && team.grade.id === grade
        }

        if (match && gender) {
          match = team.gender.value === gender
        }

        if (match && age) {
          match = team.ageGroup.value === age
        }

        return !match
      }),
    )
  }

  const filteredTeams = teams.filter(
    team => !excludedTeams.some(t => t.id === team.id),
  )

  return (
    <OrganisationPageWrapper
      organisation={setOrganisationContacts(
        organisation,
        tenantConfiguration.contactRoles,
      )}
      tenant={String(tenant)}
    >
      <Helmet
        title={`${season.competition.name} ${
          season.name
        } teams for ${organisation.name.slice(0, 20)}`}
        meta={[
          {
            name: 'description',
            content: `View ${organisation.name}'s teams in the ${season.competition.organisation.name} ${season.competition.name} ${season.name} season`,
          },
        ]}
      />
      <FullWidthBlock>
        <StyledSectionContainer noXPadding>
          <WizardStepper
            backPath={`/${tenant}/org/${slugify(organisation.name)}/${
              organisation.id
            }`}
          >
            <WizardStep count={1}>
              <SeasonStep>
                Select a Season
                <Text size="14" weight="400">
                  {season.competition.name}, {season.name}
                  <br />
                  {season.competition.organisation.name}
                </Text>
              </SeasonStep>
            </WizardStep>
            <WizardStep count={2} highlight>
              Select a Team
            </WizardStep>
          </WizardStepper>
          <BoxContainer>
            <ListWrapper>
              <AssociationLogoName
                name={season.competition.organisation.name}
                logo={season.competition.organisation.logo}
              />
              <TeamDetails>
                <TeamHeader>
                  <CompName size="18" weight="700" as="h3">
                    {season.competition.name}
                  </CompName>
                  <SeasonSubHeader>
                    <Text color="darkGrey400">{season.name}</Text>
                    <Pill
                      variant={
                        season.status.value === DiscoverSeasonStatusType.Active
                          ? 'active'
                          : season.status.value ===
                            DiscoverSeasonStatusType.Upcoming
                          ? 'upcoming'
                          : 'complete'
                      }
                      small
                      text={season.status.name}
                    />
                  </SeasonSubHeader>
                </TeamHeader>
                <FilterButton
                  onClick={() => toggleFiltersOpen()}
                  variant="tertiary"
                  icon="filter"
                  size="small"
                  iconSize="16"
                  halo
                >
                  Filters
                </FilterButton>
              </TeamDetails>
              <Formik<TeamListFilterFormValues>
                initialValues={{
                  grade: '',
                  gender: '',
                  age: '',
                }}
                onSubmit={values => filterTeams(values)}
              >
                {formProps => (
                  <FiltersContainer visible={filtersOpen}>
                    <TeamListFilterForm
                      {...formProps}
                      teams={teams}
                      grades={season.grades.map(g => ({
                        name: g.name,
                        value: g.id,
                      }))}
                      ageGroups={tenantConfiguration.competition.ageGroups}
                      genders={tenantConfiguration.competition.genders}
                      onFilterChange={() => filterTeams(formProps.values)}
                    />
                    <ResetButton
                      variant="text"
                      data-testid="reset-filters-button"
                      onClick={() => {
                        formProps.resetForm()
                      }}
                      size="small"
                    >
                      Reset
                    </ResetButton>
                  </FiltersContainer>
                )}
              </Formik>
              <StyledList data-testid="teams-list">
                <StyledHeadingRow key="heading">
                  <Text weight="700" size="14">
                    Team
                  </Text>
                  <Text weight="700" size="14">
                    Grade
                  </Text>
                  <Text weight="700" size="14">
                    Gender
                  </Text>
                  <Text weight="700" size="14">
                    Age Group
                  </Text>
                </StyledHeadingRow>
                {teams.length === 0 ? (
                  <Notification variant="empty">
                    There are currently no teams for this season.
                  </Notification>
                ) : filteredTeams.length === 0 ? (
                  <Notification variant="empty">
                    No teams match your filters.
                  </Notification>
                ) : (
                  filteredTeams.map(team => (
                    <li key={team.id}>
                      <StyledRow
                        to={`../../teams/${slugify(team.name)}/${team.id}`}
                        data-testid={`team-${team.id}-link`}
                      >
                        <RowBody>
                          <Text weight="600" size="16">
                            {team.name}
                          </Text>
                          <Text color="darkGrey400">
                            {team.grade ? team.grade.name : '-'}
                          </Text>
                          <Text color="darkGrey400">{team.gender.name}</Text>
                          <Text color="darkGrey400">{team.ageGroup.name}</Text>
                          <SelectButton
                            variant="tertiary"
                            halo
                            icon="right-arrow"
                            iconPosition="right"
                            iconSize="12"
                            size="small"
                          >
                            Select
                          </SelectButton>
                        </RowBody>
                        <RowButton>
                          <Icon
                            size="16"
                            name="right-arrow"
                            color="blackberry400"
                          />
                        </RowButton>
                      </StyledRow>
                    </li>
                  ))
                )}
              </StyledList>
            </ListWrapper>
          </BoxContainer>
        </StyledSectionContainer>
      </FullWidthBlock>
    </OrganisationPageWrapper>
  )
}

export default TeamList
